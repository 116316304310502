import React, { FC, useEffect, useState } from "react";
import Button from "../../../UI/molecules/Button/Button";
import Title from "../../../UI/organisms/Title/Title";
import { useEgoi } from "./hooks/useEgoi";
import { useBuyButton } from "./hooks/useBuyButton";

import "./Header.scss";
import { HeaderType } from "./Types";

const Header: FC<HeaderType> = ({ data, nameBlock }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const { egoi, EgoiActions } = useEgoi();
  const { BuyButtonHandle, MultiBuyButtonsHandle } = useBuyButton(
    nameBlock,
    publicUrl,
    EgoiActions
  );

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1025);
    });
  }, []);

  data.offer?.link &&
    !data.offer.link.includes("hotmart.com") &&
    EgoiActions.StartEgoi();

  return (
    <>
      <section
        id={nameBlock}
        className={`component ${data.position}`}
        style={
          nameBlock === "offer"
            ? {
                backgroundImage: `url(${publicUrl}/img/course/background-offer.png)`,
              }
            : { backgroundImage: `` }
        }
      >
        <div className={`component__content ${data.customClass || ""}`}>
          {data.image && !isMobile && (
            <div className="component__banner">
              <img
                className="component__banner--img"
                src={`${publicUrl}/${data.image}`}
                alt=""
              />
              {data.infos &&
                data.infos.map((info, index) => (
                  <div key={info.text} className={`--item-${index}`}>
                    <img src={`${publicUrl}/${info.ico}`} alt={info.text} />
                    <p dangerouslySetInnerHTML={{ __html: `${info.text}` }}></p>
                  </div>
                ))}
            </div>
          )}

          <div className="component__info">
            <Title info={data.title} tag="h1" />
            <div
              className={`component__info--price ${
                data.offer?.installments ? "has-installments" : ""
              } ${data.offer?.available === false ? "unavailable" : ""}`}
            >
              {data.offer?.price
                ? data.offer.price !== data.offer.bestPrice && (
                    <div className="old-price">
                      De <span>{data.offer.price}</span>
                    </div>
                  )
                : ""}
              {data.offer?.installments && (
                <div className="installments">
                  Por
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${data.offer.installments}`,
                    }}
                  ></span>
                </div>
              )}
              {data.offer?.bestPrice && (
                <div className="best-price">
                  {data.offer.installments
                    ? "ou"
                    : data.offer.price !== data.offer.bestPrice
                    ? "Por"
                    : ""}
                  <span>{data.offer.bestPrice}</span>
                  {data.offer.installments && "à vista"}
                </div>
              )}
              {data.offer?.note && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${data.offer.note}`,
                  }}
                  className="note-price"
                ></p>
              )}
            </div>
            {data.offer && (
              <div
                className={`component__info--buttons ${
                  data.multiCtas?.buttons && data.multiCtas.buttons.length > 1
                    ? " multi-ctas"
                    : ""
                }`}
              >
                {data.offer.link && BuyButtonHandle(data.offer)}
                {data.multiCtas && (
                  <>
                    {data.multiCtas.title && (
                      <Title info={data.multiCtas.title} tag="h3" />
                    )}{" "}
                    {data.multiCtas.buttons.map((bt) =>
                      MultiBuyButtonsHandle(bt)
                    )}
                  </>
                )}
                <Button
                  url="#questions"
                  text="Tenho dúvidas"
                  ico={
                    nameBlock === "offer"
                      ? `${publicUrl}/img/course/icons/questions-blue.svg`
                      : `${publicUrl}/img/course/icons/questions.svg`
                  }
                />
              </div>
            )}
            {data.description && (
              <div
                className="component__info--desc"
                dangerouslySetInnerHTML={{ __html: `${data.description}` }}
              ></div>
            )}
          </div>
        </div>
      </section>

      {data.offer?.link && !data.offer?.link.includes("hotmart.com") && (
        <div id="popUp-egoi" className={`${data.offer?.link} ${egoi}`}>
          <button onClick={EgoiActions.HandlePopUp}>X</button>
        </div>
      )}
    </>
  );
};

export default Header;
