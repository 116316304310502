import React, { FC } from "react";
import Title from "../../UI/organisms/Title/Title";

import "./Service.scss";
import { ServiceType } from "./Types";

const Service: FC<ServiceType> = ({ data, service }) => {

  return (
    <section id="service" className={service}>
      <div className="service__content">
        {data?.title && <Title info={data.title} tag="h2" />}
        <div className="service__info">
          <h3 className="service__info--title">{data.infos.title}</h3>
          <ul className="service__info--list">
            {data.infos.list &&
              data.infos.list.map((info) => (
                <li key={info} className="item">
                  <h3>{info}</h3>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Service;
