import React from "react";

const ArrowRight = ({ color }: { color?: string }) => {
  return (
    <svg
      width="18"
      height="30"
      viewBox="0 0 18 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1462 16.1705L3.79594 29.5207C3.4877 29.8275 3.07051 29.9998 2.63562 29.9998C2.20073 29.9998 1.78354 29.8275 1.47531 29.5207L0.492103 28.5375C0.184971 28.2295 0.0125025 27.8122 0.0125024 27.3772C0.0125024 26.9422 0.184971 26.525 0.492103 26.2169L11.702 15.0063L0.478616 3.78293C0.171611 3.47466 -0.000757665 3.05732 -0.000757703 2.62225C-0.000757742 2.18719 0.171611 1.76985 0.478616 1.46158L1.46181 0.479093C1.77005 0.172304 2.18724 7.38288e-05 2.62213 7.37908e-05C3.05702 7.37528e-05 3.47421 0.172303 3.78245 0.479093L17.1461 13.8414C17.4528 14.1514 17.6248 14.5699 17.6248 15.0059C17.6248 15.442 17.4528 15.8605 17.1462 16.1705Z"
        fill={color || "#002E94"}
      />
    </svg>
  );
};
export default ArrowRight;
