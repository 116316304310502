import React, { FC } from "react";

import "./Advantages.scss";
import { IcoText } from "../../../../types/CoursesTypes";

type AdvantagesType = {
  data: IcoText[];
};

const Advantages: FC<AdvantagesType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  const text = (text: string) =>
    text && (
      <p
        className="advantages__item--title"
        dangerouslySetInnerHTML={{ __html: `${text}` }}
      ></p>
    );

  return (
    <section id="advantages" className="advantages__content">
      <ul className="advantages__list">
        {data &&
          data.map((info) => (
            <li key={info.text} className="advantages__item">
              <img
                className="advantages__item--img"
                src={`${publicUrl}/${info.ico}`}
                alt={info.text}
              />
              {info.link ? (
                <a href={info.link}>{text(info.text)}</a>
              ) : (
                text(info.text)
              )}
            </li>
          ))}
      </ul>
    </section>
  );
};

export default Advantages;
