import React, { FC } from "react";

import Button from "../../molecules/Button/Button";
import Links from "../../molecules/Links/Links";

import { NavbarLinksType } from "./Type";

const NavbarLinks: FC<NavbarLinksType> = ({ links, dropdown, buttons }) => {
  return (
    <div className="menus">
      <ul className="navbar__links">
        {links.length > 0 &&
          links.map((link, index) => (
            <li key={`${link.text}-${index}`} className={`navbar__links--link ${link.text && link.text.toLowerCase().replaceAll(" ", "-")}`} >
              <Links text={link.text} url={link.link} />
            </li>
          ))}
        {
          dropdown && dropdown.links.length > 0 && (<>

            {window.innerWidth > 1024 ?
              <li className={`navbar__links--dropdown`} >
                <p className="dropdown--label" >{dropdown.title}</p>
                <div className="dropdown--links">
                  {dropdown.links.map((e) => <Links key={`${e.text?.toLowerCase().replace(" ", "-")}`} text={e.text} url={e.link} />)}
                </div>
              </li>
              : dropdown.links.map((e, index) => (
                <li key={`${e.text}-${index}`} className={`navbar__links--link ${e.text && e.text.toLowerCase().replaceAll(" ", "-")}`} >
                  <Links key={`${e.text?.toLowerCase().replace(" ", "-")}`} text={e.text} url={e.link} />
                </li>
              ))
            }
          </>
          )


        }

      </ul>

      <ul className="navbar__buttons">
        {buttons.length > 0 &&
          buttons.map((button, index) => (
            <li
              key={`${button.text}-${index}`}
              className={`--button ${button.text && button.text.toLowerCase().replaceAll(" ", "-")
                }`}
            >
              <Button text={button.text} url={button.link} />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NavbarLinks;
