import Button from "../../../../UI/molecules/Button/Button";

export function useBuyButton(nameBlock: string, publicUrl: string, EgoiActions: any) {
  const BuyButtonHandle = (offer: any) =>
    offer.link.includes("hotmart.com") ? (
      <Button
        nameClass="start-now"
        url={offer?.link}
        text={offer.buyBt ? offer.buyBt : "Quero começar!"}
        ico={
          nameBlock === "offer" ? `${publicUrl}/img/course/icons/cart.svg` : ""
        }
      />
    ) : (
      <button
        id="starthere"
        onClick={EgoiActions.HandlePopUp}
        className={`button`}
      >
        <img
          src={`${publicUrl}/img/course/icons/cart.svg`}
          alt={offer.buyBt ? offer.buyBt : "Quero começar!"}
        />
        {offer.buyBt ? offer.buyBt : "Quero começar!"}
      </button>
    );

  const MultiBuyButtonsHandle = (ctas: any) => (
    <Button
      nameClass={`start-now ${ctas.flag || ""}`}
      url={`${ctas?.link}`}
      text={ctas.buyBt ? ctas.buyBt : "Quero começar!"}
      ico={
        nameBlock === "offer" ? `${publicUrl}/img/course/icons/cart.svg` : ""
      }
      disabled={ctas.flag === "sold-off" ? true : false}
    />
  );

  return {
    MultiBuyButtonsHandle,
    BuyButtonHandle,
  };
}
