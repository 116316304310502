import React, { FC } from "react";

import "./Links.scss";

type LinksProps = {
  url: string;
  text?: string;
  ico?: string;
};

const Links: FC<LinksProps> = ({ url, text, ico }) => {
  // const { language } = useSelector((state: RootState) => state.lang);

  return (
    <a
      className="link"
      href={url}
      title={text || "link"}
      aria-label="Link de acesso"
    >
      {ico && <img src={ico} alt={`Icone sobre ${text}` || "Icone de caracterização"} />}
      {text && text}
    </a>
  );
};

export default Links;
