import React, { FC } from "react";
import Footer from "../../templates/Footer/Footer";
import Navbar from "../../templates/Navbar/Navbar";

import "./Error.scss";

const NotFound: FC = () => {
  return (
    <>
      <Navbar />
      <div className="page-404">
        <div className="page-404__content">
          <h1>Ops, não encontramos essa pagina!</h1>
          <a href="/"> &#8592; Voltar ao inicio</a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
