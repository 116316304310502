import React, { FC, useState } from "react";
import Button from "../../../UI/molecules/Button/Button";
import Title from "../../../UI/organisms/Title/Title";
import Video from "../../../UI/organisms/Video";

import "./Header.scss";
import { HeaderType } from "../Header/Types";

const ColHeader: FC<HeaderType> = ({ data, nameBlock }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const [egoiScript, setEgoiScript] = useState("");
  const [egoi, setEgoi] = useState("close");

  const BuyButtonHandle = (offer: any) =>
    offer.link.includes("hotmart.com") ? (
      <Button
        nameClass="start-now"
        url={offer?.link}
        text={offer.buyBt ? offer.buyBt : "Quero começar!"}
        ico={
          nameBlock === "offer" ? `${publicUrl}/img/course/icons/cart.svg` : ""
        }
      />
    ) : (
      <button
        id="starthere"
        onClick={EgoiActions.HandlePopUp}
        className={`button`}
      >
        <img
          src={`${publicUrl}/img/course/icons/cart.svg`}
          alt={offer.buyBt ? offer.buyBt : "Quero começar!"}
        />
        {offer.buyBt ? offer.buyBt : "Quero começar!"}
      </button>
    );

  const MultiBuyButtonsHandle = (ctas: any) => (
    <Button
      nameClass="star-now"
      url={ctas?.link}
      text={ctas.buyBt ? ctas.buyBt : "Quero começar!"}
      ico={
        nameBlock === "offer" ? `${publicUrl}/img/course/icons/cart.svg` : ""
      }
    />
  );

  const EgoiActions = {
    StartEgoi: () => {
      const body = document.querySelector("body");
      if (body && egoiScript === "") {
        const script = document.createElement("script");
        script.innerHTML += `
            if(window._mtmReady){ console.error('Connected sites script already loaded. You might have it dupplicated.');} 
            else { 
              window._mtmReady=true; 
              var _mtm = window._mtm = window._mtm || [];
              _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src='https://egoi.site/306319_institutovalquiriaarenas.com.br.js'; 
              s.parentNode.insertBefore(g,s);
            }`;

        body.append(script);
        setEgoiScript((egoiScript) => (egoiScript = "Inserido"));
      }
    },
    HandlePopUp: () => {
      setEgoi((egoi) => (egoi = egoi === "close" ? "open" : "close"));
    },
  };
  data.offer &&
    data.offer.link &&
    !data.offer.link.includes("hotmart.com") &&
    EgoiActions.StartEgoi();

  return (
    <>
      <section
        id={nameBlock}
        className={`component-video ${data.position}`}
        style={
          nameBlock === "offer"
            ? {
                backgroundImage: `url(${publicUrl}/img/course/background-offer.png)`,
              }
            : { backgroundImage: `` }
        }
      >
        <div className="component-video__content">
          {data.title && <Title info={data.title} tag="h1" />}
          {data.image && data.image.includes("youtube") ? (
            <div className="component-video__banner">
              <Video url={data.image} />
            </div>
          ) : (
            <div className="component-image__banner">
              <img
                className="component-image__banner--img"
                src={`${publicUrl}/${data.image}`}
                alt=""
              />
            </div>
          )}
          {data.description && (
            <div
              className="title__desc"
              dangerouslySetInnerHTML={{ __html: `${data.description}` }}
            ></div>
          )}

          {data.offer && (
            <div
              className={`component__info--buttons ${
                data.multiCtas?.buttons && data.multiCtas.buttons.length > 1
                  ? " multi-ctas"
                  : ""
              }`}
            >
              {data.offer.link && BuyButtonHandle(data.offer)}
              {data.multiCtas && (
                <>
                  {data.multiCtas.title && (
                    <Title info={data.multiCtas.title} tag="h3" />
                  )}{" "}
                  {data.multiCtas.buttons.map((bt) =>
                    MultiBuyButtonsHandle(bt)
                  )}
                </>
              )}
              <Button
                url="#questions"
                text="Tenho dúvidas"
                ico={
                  nameBlock === "offer"
                    ? `${publicUrl}/img/course/icons/questions-blue.svg`
                    : `${publicUrl}/img/course/icons/questions.svg`
                }
              />
            </div>
          )}
        </div>
      </section>
      {data.offer?.link && !data.offer.link.includes("hotmart.com") && (
        <div id="popUp-egoi" className={`${data.offer?.link} ${egoi}`}>
          <button onClick={EgoiActions.HandlePopUp}>X</button>
        </div>
      )}
    </>
  );
};

export default ColHeader;
