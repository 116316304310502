import React, { FC } from "react";
import Slider from "react-slick";

import Card from "../../../UI/organisms/Card/Card";
import Title from "../../../UI/organisms/Title/Title";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Teachers.scss";

import { TeachersType } from "./Types";
import ArrowLeft from "../../../UI/atoms/arrowLeft";
import ArrowRight from "../../../UI/atoms/arrowRight";

const Teachers: FC<TeachersType> = ({ data }) => {
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    prevArrow: (
      <div className="arrow-left">
        <ArrowLeft />
      </div>
    ),
    nextArrow: (
      <div className="arrow-left">
        <ArrowRight />
      </div>
    ),
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  };

  return (
    <section id="teachers">
      <div className="teachers__content">
        <Title info={data.title} tag="h2"/>
        <Slider className="teachers__list" {...settings}>
          {data.cards &&
            data.cards.map((card) => (
              <Card
                key={card.title}
                info={card}
                classItem={"teachers__list--item"}
              />
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Teachers;
