import React, { FC } from "react";
import Title from "../../UI/organisms/Title/Title";

import "./Public.scss";
import { PublicType } from "./Types";

const Public: FC<PublicType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="public">
      <div className="public__content">
        <Title info={data.title} tag="h2" />
        <ul className="public__list">
          {data &&
            data.list.map((info) => (
              <li key={info.title} className="public__item">
                <img
                  className="public__item--img"
                  src={`${publicUrl}/${info.image}`}
                  alt={info.title}
                />
                {info.title && (
                  <p
                    className="public__item--title"
                    dangerouslySetInnerHTML={{ __html: `${info.title}` }}
                  ></p>
                )}
                {info.description && (
                  <p className="public__item--desc">{info.description}</p>
                )}
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default Public;
