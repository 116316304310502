import React, { FC } from "react";
import Title from "../../UI/organisms/Title/Title";

import "./Benefits.scss";
import { BenefitsType } from "./Types";

const Benefits: FC<BenefitsType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="benefits">
      <div className="benefits__content">
        <Title info={data.title} tag="h2" />
        <ul className="benefits__list">
          {data &&
            data.list.map((info) => (
              <li key={info.title} className="benefits__item">
                <img
                  className="benefits__item--img"
                  src={`${publicUrl}/${info.image}`}
                  alt={info.title}
                />
                {info.title && (
                  <p
                    className="benefits__item--title"
                    dangerouslySetInnerHTML={{ __html: `${info.title}` }}
                  ></p>
                )}
                {info.description && (
                  <p
                    className="benefits__item--description"
                    dangerouslySetInnerHTML={{ __html: `${info.description}` }}
                  ></p>
                )}
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default Benefits;
