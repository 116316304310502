import React, { FC } from "react";
import Slider from "react-slick";

import { HeaderType, BannersType } from "./Types";

import "./Header.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Title from "../../UI/organisms/Title/Title";
import ArrowLeft from "../../UI/atoms/arrowLeft";
import ArrowRight from "../../UI/atoms/arrowRight";
import HeaderBanner from "../../UI/organisms/HeaderBanner/HeaderBanner";

const Header: FC<HeaderType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    prevArrow: (
      <div className="arrow-left">
        <ArrowLeft />
      </div>
    ),
    nextArrow: (
      <div className="arrow-left">
        <ArrowRight />
      </div>
    ),
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="header">
      <div
        className="header__content"
        style={{
          backgroundImage: `url(${publicUrl}/img/banners/banner-background-1.png)`,
        }}
      >
        {data.title && <Title info={data.title} tag="h2" />}
        <Slider className="header__banners" {...settings}>
          {data.banners &&
            data.banners.map((banner: BannersType, index: any) => (
              <HeaderBanner
                key={index}
                info={banner}
                classItem={"header__banner--item"}
              />
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Header;
