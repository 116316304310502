import React, { FC } from "react";
import Title from "../../../UI/organisms/Title/Title";
import "./Partners.scss";

interface Info {
  image: string;
  title: string;
  description?: string;
}
type PartnersType = {
  list?: Info[];
  type?: string;
};

const Partners: FC<PartnersType> = ({ list, type }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const titleData =
    type === "partners"
      ? {
          intro: "Conheça os nossos",
          title: "Patrocinadores e parceiros",
        }
      : {
          intro: "Conheça também os nossos",
          title: "Apoiadores",
        };

  return (
    <section id="partners" className="partners__content">
      <Title info={titleData} tag="h2" />
      <ul className="partners__list">
        {list?.map((info) => (
          <li key={info.title} className="partners__list--item">
            <img
              src={`${publicUrl}/${info.image}`}
              alt={`Patrocinador/Parceiro ${info.title}`}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Partners;
