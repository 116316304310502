import React, { FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";

import Home from "./components/pages/Home";
import Clients from "./components/pages/Clients";
import Course from "./components/pages/Course";
import CoursesPage from "./components/pages/Courses";
import NotFound from "./components/pages/Error/404";
import Services from "./components/pages/Services";

const App: FC = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/outros-servicos" element={<Services />} />
          <Route path="/clientes-e-parceiros" element={<Clients />} />
          <Route path="/cursos" element={<CoursesPage />} />
          <Route path="/cursos/:op" element={<CoursesPage />} />
          <Route path="/curso/:id" element={<Course />} />
          <Route
            path="/nos-conheca-melhor.pdf"
            element={`${process.env.PUBLIC_URL}/nos-conheca-melhor.pdf`}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
