import { FC } from "react";

import "./Title.scss";
import { TitleType } from "./Types";

const Title: FC<TitleType> = ({ info, tag }) => {
  return (
    <div className="title box">
      {info.intro && <p className="title__intro">{info.intro}</p>}
      {tag === "h1" ? (
        <h1 className="title__text">{info.title}</h1>
      ) : tag === "h2" ? (
        <h2 className="title__text">{info.title}</h2>
      ) : (
        <h3 className="title__text">{info.title}</h3>
      )}
      {info.description && (
        <div
          className="title__desc"
          dangerouslySetInnerHTML={{ __html: `${info.description}` }}
        ></div>
      )}
    </div>
  );
};

export default Title;
