import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { coursePageData } from "../../../data";

import "./Course.scss";

import NotFound from "../Error/404";

import Navbar from "../../templates/Navbar/Navbar";
import Footer from "../../templates/Footer/Footer";

import Header from "../../templates/CoursesPage/Header/Header";
import Public from "../../templates/Public/Public";
import Teachers from "../../templates/CoursesPage/Teachers/Teachers";
import Advantages from "../../templates/CoursesPage/Advantages/Advantages";
import Modules from "../../templates/CoursesPage/Modules/Modules";
import Questions from "../../templates/CoursesPage/Questions/Questions";
import Partners from "../../templates/CoursesPage/Partners/Partners";
import Assurance from "../../templates/CoursesPage/Assurance/Assurance";
import Courses from "../../templates/Courses/Courses";
import ColHeader from "../../templates/CoursesPage/ColHeader/Header";
import Reviews from "../../templates/CoursesPage/Reviews/Reviews";
import TabLayout from "../../templates/CoursesPage/TabLayout/TabLayout";

const Course: FC = () => {
  const { id } = useParams();
  const [course, setCourse] = React.useState(
    coursePageData("pt", `courses/${id}`)
  );

  useEffect(() => {
    const titleClass = course?.header.title.title
      .toLowerCase()
      .replace(" – ", "-")
      .replaceAll(" ", "-");
    document.querySelector("body")?.classList.add(`course-page`);
    document.querySelector("body")?.classList.add(`${titleClass}`);
    // document
    //   .querySelector("body")
    //   ?.classList.add(`${course?.header.title.title}`);
    if (course?.header) {
      document.title = `${course.header.title.title} || Instituto Valquiria Arenas`;
    }
  }, [course]);

  useEffect(() => {
    setCourse(coursePageData("pt", `courses/${id}`));
  }, [id]);

  if (course)
    return (
      <>
        <Navbar />
        {course.header && course.header.type === "column" ? (
          <ColHeader data={course.header} nameBlock="header" />
        ) : (
          <Header data={course.header} nameBlock="header" />
        )}
        {course.advantagesRow && <Advantages data={course.advantagesRow} />}
        {course.reviewsTop && (
          <Reviews data={course.reviewsTop} position="top" />
        )}
        {course.about && (
          <section
            id="about"
            className="about__content course"
            dangerouslySetInnerHTML={{ __html: `${course.about}` }}
          ></section>
        )}
        {course.location && course.location.tabLayout && (
          <TabLayout data={course.location.tabLayout} />
        )}
        {course.public && <Public data={course.public} />}
        {course.modules && <Modules data={course.modules} />}
        {course.commertialText && (
          <section
            id="commertialText"
            className="commertialText__content course"
            dangerouslySetInnerHTML={{ __html: `${course.commertialText}` }}
          ></section>
        )}
        {course.modules2 && <Modules data={course.modules2} />}
        {course.reviewsMiddle && (
          <Reviews data={course.reviewsMiddle} position="middle" />
        )}
        {course.bonus && <Modules data={course.bonus} styleClass="bonus" />}
        {course.reviewsBottom && (
          <Reviews data={course.reviewsBottom} position="middle" />
        )}
        {course.teachers && <Teachers data={course.teachers} />}
        {course.offer && <Header data={course.offer} nameBlock="offer" />}
        {course.assurance && <Assurance data={course.assurance} />}
        {course.partners && <Partners list={course.partners} type="partners" />}
        {course.helpers && <Partners list={course.helpers} type="helpers" />}
        {course.courses && <Courses data={course.courses} />}
        {course.questions && <Questions questions={course.questions} />}
        <Footer />
      </>
    );
  else
    return (
      <>
        <NotFound />
      </>
    );
};

export default Course;
