import React, { FC } from "react";

import Button from "../../molecules/Button/Button";
import { ContactType } from "./Type";

import "./Contact.scss";

const Contact: FC<ContactType> = ({ info }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <div
    id="contact"
      className="contact-us"
      style={{ "backgroundImage": `url(${publicUrl}/img/parts/support-marca-d-agua.svg)` }}
    >
      <div className="contact-us__content">
        <p>{info.textLittle}</p>
        <h2>{info.textBig}</h2>
        <Button text={info.button.text} url={info.button.link} ico={`${publicUrl}/${info.button.ico}`} />
      </div>
    </div>
  );
};

export default Contact;
