import React, { FC } from "react";

import "./Button.scss";

type ButtonProps = {
  url: string;
  text?: string;
  ico?: string;
  nameClass?: string;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({ url, text, ico, nameClass, disabled }) => {
  // const { language } = useSelector((state: RootState) => state.lang);

  if (disabled)
    return (
      <p className={`button ${nameClass ? nameClass : ""}`}>
        {ico && <img src={ico} alt={text} />}
        {text && text}
      </p>
    );
    
  return (
    <a className={`button ${nameClass ? nameClass : ""}`} href={url}>
      {ico && <img src={ico} alt={text} />}
      {text && text}
    </a>
  );
};

export default Button;
