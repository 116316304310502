import React, { FC, useEffect } from "react";
import { clientsData } from "../../../data";

import "./Clients.scss";

import Navbar from "../../templates/Navbar/Navbar";
import Footer from "../../templates/Footer/Footer";
import Title from "../../UI/organisms/Title/Title";

const Clients: FC = () => {
  const [data, setData] = React.useState(clientsData("pt", `clients`));
  const publicUrl = process.env.PUBLIC_URL;

  useEffect(() => {
    setData(clientsData("pt", `clients`));

    document.title = "Clientes e Parceiros || Instituto Valquiria Arenas";
    document.querySelector("body")?.classList.add("clients-partners-page");
  }, []);

  return (
    <>
      <Navbar />
      <section id="banner">
        <img
          src={`${publicUrl}/${data?.banner}`}
          alt="Banner clientes e serviços"
        />
      </section>
      <section id="clients" className="content">
        {data?.clients.title && <Title tag="h1" info={data?.clients.title} />}
        <ul className="clients__list">
          {data?.clients.list &&
            data.clients.list.map((client) => (
              <li className="clients__list--item">
                <Title tag="h2" info={client} />
              </li>
            ))}
        </ul>
      </section>
      <section id="partners" className="content">
        {data?.partners.title && <Title tag="h2" info={data?.partners.title} />}
        <ul className="partners__list">
          {data?.partners.list &&
            data.partners.list.map((partner, i) => (
                <li className="partners__list--item">
                  <h3 className="text">{partner.text}</h3>
                  <a className="link" rel="noreferrer" target="_blank" href={`https://${partner.link}`}>
                    {partner.link}
                  </a>
                </li>
            ))}
        </ul>
      </section>
      <Footer />
    </>
  );
};

export default Clients;
