import React, { useState } from "react";

import "./MenuButton.scss";

const MenuButton = () => {
  const [active, setActive] = useState("close");

  const handleChangeMenu = () => {
    setActive(active => active === "open" ?  "close" : "open");
  };

  return (
    <button
      className={`navbar__menu-bt ${active}`}
      onClick={handleChangeMenu}
      title="Fechar o menu de navegação"
    >
        <span></span>
        <span></span>
        <span></span>
    </button>
  );
};

export default MenuButton;
