import { FC } from "react";

import "./Card.scss";
import { CardType } from "./Types";
import Video from "../Video";

const Card: FC<CardType> = ({ info, classItem }) => {
  const publicUrl = process.env.PUBLIC_URL;

  const CardInfo = () => <>
    {info.flag && (
      <div className="card__flag">
        <img className="card__flag--ico" src={`${publicUrl}/${info.flag.ico}`} alt={`flag ${info.flag.text}`} />
        <span className="card__flag--text">{info.flag.text}</span>
      </div>
    )}
    <div className="card__image">
      {info.image.includes("youtube") || info.image.includes("drive.google") || info.image.includes("video") ?
        <Video url={info.image} /> :
        <img src={`${publicUrl}/${info.image}`} alt={info.title} />
      }
    </div>
    {info.title && (
      <div className="card__text" dangerouslySetInnerHTML={{ __html: `${info.title}` }}></div>
    )}
    {info.description && (
      <div className="card__desc" dangerouslySetInnerHTML={{ __html: `${info.description}` }}></div>
    )}
  </>

  if (info.link)
    return (
      <a href={info.link} className={`card box ${classItem}`}>
        <CardInfo />
      </a>
    );
  else
    return (
      <div className={`card box ${classItem}`}>
        <CardInfo />
      </div>
    );
};

export default Card;
