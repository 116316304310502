import React, { FC, useEffect } from "react";
import { homeData } from "../../../data";

import "./Home.scss";

import Navbar from "../../templates/Navbar/Navbar";
import Footer from "../../templates/Footer/Footer";

import Header from "../../templates/Header/Header";
import About from "../../templates/About/About";
import References from "../../templates/References/References";
import Courses from "../../templates/Courses/Courses";
import Public from "../../templates/Public/Public";
import Benefits from "../../templates/Benefits/Benefits";

import CallToOffer from "../../templates/CallToOffer/CallToOffer";

const Home: FC = () => {
  const [data, setData] = React.useState(homeData("pt", `home`));

  useEffect(() => {
    setData(homeData("pt", `home`));

    if (window.location.hash !== "") {
      const el = document.querySelector(window.location.hash);
      if (el) el.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Navbar />
      {data?.header && <Header data={data.header} />}
      <CallToOffer />
      {data?.about && <About data={data.about} />}
      {data?.events && <Courses data={data.events} />}
      {data?.references && <References data={data.references} />}
      {data?.courses && <Courses data={data.courses} />}
      {data?.public && <Public data={data.public} />}
      {data?.benefits && <Benefits data={data?.benefits} />}
      <Footer />
    </>
  );
};

export default Home;
