import React from "react";
import Title from "../../../UI/organisms/Title/Title";

import { QuestionsType } from "./Type";

import "./Questions.scss";

const Questions = ({ questions }: { questions: QuestionsType }) => {
  const titleData = {
    intro: "Dê uma olhada nas nossas",
    title: "Perguntas frequentes ",
  };

  function openQuestion(t: EventTarget | HTMLElement) {
    if (t instanceof HTMLElement) {
      if (t.classList.contains("questions__list--item"))
        t.classList.toggle("open");
      else
        t.parentElement?.classList.contains("questions__list--item")
          ? t.parentElement?.classList.toggle("open")
          : t.parentElement?.parentElement?.classList.toggle("open");
    }
  }

  return (
    <section id="questions" className="questions__content">
      <Title info={titleData} tag="h2" />

      <div className="questions__list">
        {questions.map((q) => (
          <div
            key={q.question}
            className="questions__list--item"
            onClick={(e) => openQuestion(e.target)}
          >
            <div className="title">{q.question}</div>
            <div className="text">
              <p dangerouslySetInnerHTML={{ __html: `${q.answer}` }}></p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Questions;
