import React, { FC, useEffect } from "react";
import { servicesData } from "../../../data";

import "./Services.scss";

import Navbar from "../../templates/Navbar/Navbar";
import Footer from "../../templates/Footer/Footer";

import Title from "../../UI/organisms/Title/Title";
import Service from "../../templates/Service/Service";

const Services: FC = () => {
  const [data, setData] = React.useState(servicesData("pt", `services`));
  const publicUrl = process.env.PUBLIC_URL;

  useEffect(() => {
    setData(servicesData("pt", `services`));

    document.title = "Outros Serviços || Instituto Valquiria Arenas";
    document.querySelector("body")?.classList.add("others-services-page");
  }, []);

  return (
    <>
      <Navbar />
      <section id="banner">
        <img
          src={`${publicUrl}/${data?.banner.image}`}
          alt="Banner clientes e serviços"
        />
        {data?.banner.title && <Title info={data?.banner.title} tag="h1" />}
      </section>

      {data?.consultancy && (
        <Service data={data.consultancy} service="consultancy" />
      )}
      {data?.advisory && (
        <Service data={data.advisory} service="advisory" />
      )}
      {data?.trainings && (
        <Service data={data.trainings} service="trainings" />
      )}

      <Footer />
    </>
  );
};

export default Services;
