import React from "react";

import "./InstaPost.scss";

import InstaIcons from "../../atoms/instaIcons";
import ArrowRight from "../../atoms/arrowRight";

const InstaPost = ({
  url,
  text,
  textarea,
  image,
}: {
  url?: string;
  text?: string;
  textarea?: {
    url: string;
    text: string;
    target?: string;
  };
  image: string;
}) => {
  const publicUrl = process.env.PUBLIC_URL;

  const TextArea = () => (
    <a
      className="banner__text-area"
      href={textarea?.url}
      title={textarea?.text}
    >
      <h3
        className="banner__text"
        dangerouslySetInnerHTML={{ __html: `${textarea?.text}` }}
      ></h3>
      <div className="banner__go">
        <div className="container">
          <ArrowRight />
          <ArrowRight />
        </div>
      </div>
    </a>
  );
  const InstaIconsArea = () => (
    <a
      className="insta-post__footer"
      href={url || "https://www.instagram.com/institutovalquiriaarenas/"}
      title="Redirecionamento para a pagina do instagran do InstitutoValquiria Arenas"
    >
      <InstaIcons />
    </a>
  );

  return (
    <div className="insta-post">
      <a
        className="insta-post__header"
        href={url || "https://www.instagram.com/institutovalquiriaarenas/"}
        title="Redirecionamento para a pagina do instagran do Instituto Valquiria Arenas"
      >
        <div className="header-image">
          <img
            src={`${publicUrl}/img/banners/insta-photo.jpeg`}
            alt="Foto de Perfil no instagran do Instituto Valquiria Arenas"
          />
        </div>
        <p>@institutovalquiriaarenas</p>
      </a>
      <div className="insta-post__image">
        {image && <img src={image} alt={text || "Imagem do banner"} />}
      </div>

      {window.innerWidth > 1024 && textarea ? (
        <InstaIconsArea />
      ) : (
        <>
          <TextArea /> <InstaIconsArea />
        </>
      )}
    </div>
  );
};

export default InstaPost;
