import React, { FC } from "react";
import Title from "../../../UI/organisms/Title/Title";

import "./Modules.scss";
import { ModulesType } from "./Types";

const Modules: FC<ModulesType> = ({ data, styleClass }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="modules" className={`modules__content ${styleClass}`}>
      <Title info={data.title} tag="h2" />
      <ul className="modules__list">
        {data &&
          data.list.map((info) => (
            <li key={info.title} className="modules__item">
              {" "}
              {info.title && (
                <p
                  className="modules__item--title"
                  dangerouslySetInnerHTML={{ __html: `${info.title}` }}
                ></p>
              )}
              <img
                className="modules__item--img"
                src={`${publicUrl}/img/course/icons/check-module.svg`}
                alt={info.title}
              />
            </li>
          ))}
      </ul>
    </section>
  );
};

export default Modules;
