import { useState } from "react";

export function useEgoi() {
  const [egoiScript, setEgoiScript] = useState("");
  const [egoi, setEgoi] = useState("close");

  const EgoiActions = {
    StartEgoi: () => {
      const body = document.querySelector("body");
      if (body && egoiScript === "") {
        const script = document.createElement("script");
        script.innerHTML += `
              if(window._mtmReady){ console.error('Connected sites script already loaded. You might have it dupplicated.');} 
              else { 
                window._mtmReady=true; 
                var _mtm = window._mtm = window._mtm || [];
                _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src='https://egoi.site/306319_institutovalquiriaarenas.com.br.js'; 
                s.parentNode.insertBefore(g,s);
              }`;

        body.append(script);
        setEgoiScript((egoiScript) => (egoiScript = "Inserido"));
      }
    },
    HandlePopUp: () => {
      setEgoi((egoi) => (egoi = egoi === "close" ? "open" : "close"));
    },
  };

  return {
    EgoiActions,
    egoi,
  };
}
