import { Teachers } from "../../../../types/CoursesTypes";
import ArrowLeft from "../../../UI/atoms/arrowLeft";
import ArrowRight from "../../../UI/atoms/arrowRight";

const responsive = (op: string | undefined) => [
  {
    breakpoint: 8000,
    settings: {
      slidesToShow: op === "image" ? 4 : 3,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
    },
  },
  {
    breakpoint: 1300,
    settings: {
      slidesToShow: op === "image" ? 3 : 2,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: op === "image" ? 2 : 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 472,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const settings = (data: Teachers) => {
  return {
    dots: true,
    infinite: false,
    arrows: true,
    prevArrow: (
      <div className="arrow-left">
        <ArrowLeft />
      </div>
    ),
    nextArrow: (
      <div className="arrow-left">
        <ArrowRight />
      </div>
    ),
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: responsive(data.type && data.type),
  };
};
