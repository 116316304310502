import React from "react";

const ArrowLeft = () => {
  return (
    <svg
      width="18"
      height="30"
      viewBox="0 0 18 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.47836 13.8295L13.8286 0.479262C14.1368 0.172474 14.554 0.000244141 14.9889 0.000244141C15.4238 0.000244141 15.841 0.172474 16.1492 0.479262L17.1324 1.46246C17.4395 1.77052 17.612 2.18777 17.612 2.62278C17.612 3.05778 17.4395 3.47504 17.1324 3.7831L5.92252 14.9937L17.1459 26.2171C17.4529 26.5253 17.6253 26.9427 17.6253 27.3778C17.6253 27.8128 17.4529 28.2302 17.1459 28.5384L16.1627 29.5209C15.8545 29.8277 15.4373 29.9999 15.0024 29.9999C14.5675 29.9999 14.1503 29.8277 13.8421 29.5209L0.47836 16.1586C0.171734 15.8486 -0.000244141 15.4301 -0.000244141 14.9941C-0.000244141 14.558 0.171734 14.1395 0.47836 13.8295Z"
        fill="#002E94"
      />
    </svg>
  );
};
export default ArrowLeft;
