import React, { FC } from "react";
import Title from "../../../UI/organisms/Title/Title";

import "./Assurance.scss";
import { AssuranceType } from "./Types";

const Assurance: FC<AssuranceType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="assurance" className="assurance__content">
      <Title info={data.title} tag="h2"/>
      <img src={`${publicUrl}/${data.image}`} alt="" className="assurance__img" />
    </section>
  );
};

export default Assurance;
