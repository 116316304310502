import React, { FC } from "react";
import Title from "../../UI/organisms/Title/Title";

import "./About.scss";
import { AboutType } from "./Types";

const About: FC<AboutType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="about">
      <div className="about__content">
        <Title info={data.title} tag="h2" />
        {data.image && (
          <img
            className="about__image"
            src={`${publicUrl}/${data.image}`}
            alt=""
          />
        )}
      </div>
    </section>
  );
};

export default About;
