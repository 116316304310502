import { FC } from "react";

import "./HeaderBanner.scss";
import { HeaderBannerType } from "./Types";

import InstaPost from "../../molecules/InstaPost/InstaPost";

const HeaderBanner: FC<HeaderBannerType> = ({ info, classItem }) => {
  const TextArea = () => (
    <div className="banner__text-area">
      <h3
        className="banner__text"
        dangerouslySetInnerHTML={{ __html: `${info.text}` }}
      ></h3>
      {info.button.link && (
        <a
          className="banner__link"
          target={info.button.target}
          href={info.button.link}
          title={info.text}
        >
          {info.button.text || "Acessar"}
        </a>
      )}
    </div>
  );

  return (
    <div className={`${classItem} banner__content`}>
      {window.innerWidth > 1024 && <TextArea />}
      <div className="banner__images">
        {info.images && window.innerWidth > 1024 ? (
          <InstaPost
            image={info.images}
            textarea={{
              url: info.button.link,
              text: info?.mobile?.text || info.text,
            }}
          />
        ) : (
          <InstaPost
            image={info.mobile.images}
            textarea={{
              url: info.button.link,
              text: info?.mobile?.text || info.text,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderBanner;
