import React, { FC } from "react";
import Slider from "react-slick";

import Card from "../../../UI/organisms/Card/Card";
import Title from "../../../UI/organisms/Title/Title";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Reviews.scss";

import { ReviewsType } from "./Types";
import { settings } from "./slickSettings";

const Reviews: FC<ReviewsType> = ({ data, position }) => {
  return (
    <section id="reviews" className={`reviews-${position}`}>
      <div className="reviews__content">
        <Title info={data.title} tag="h2" />
        <Slider className="reviews__list" {...settings(data)}>
          {data.cards &&
            data.cards.map((card, index) => (
              <Card
                key={`${index}-${card.title}`}
                info={card}
                classItem={"reviews__list--item"}
              />
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Reviews;
