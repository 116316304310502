import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { coursesData } from "../../../data";

import "./Courses.scss";

import Navbar from "../../templates/Navbar/Navbar";
import Footer from "../../templates/Footer/Footer";

import Courses from "../../templates/Courses/Courses";
import Title from "../../UI/organisms/Title/Title";

const CoursesPage: FC = () => {
  const { op } = useParams();
  const [data, setData] = React.useState(coursesData("pt", `courses`));
  const publicUrl = process.env.PUBLIC_URL;

  useEffect(() => {
    setData(coursesData("pt", `courses`));

    document.title = "Nossos Cursos Onlines || Instituto Valquiria Arenas";
    document.querySelector("body")?.classList.add("all-courses-page");
  }, []);

  return (
    <>
      <Navbar />
      <section id="banner">
        <img
          src={`${publicUrl}/${data?.banner.image}`}
          alt="Banner clientes e serviços"
        />
        {data?.banner.title && <Title info={data?.banner.title} tag="h1" />}
      </section>

      {(!op || (op !== "eventos" && op !== "online")) && (
        <>
          {data?.events && <Courses data={data.events} />}
          {data?.courses && <Courses data={data.courses} />}
        </>
      )}
      {op === "eventos" && data?.events && <Courses data={data?.events} />}
      {op === "online" && data?.courses && <Courses data={data?.courses} />}

      <Footer />
    </>
  );
};

export default CoursesPage;
