import React, { useEffect, useState } from "react";

import { defaultData } from "../../../data";

import "./Navbar.scss";
import NavbarLinks from "../../UI/organisms/NavbarLinks/NavbarLinks";
import MenuButton from "../../UI/molecules/MenuButton/MenuButton";
// import PopUp from "../PopUp/PopUp";
// import Maintenance from "../Maintenance/Maintenance";

const Navbar = () => {
  const [data, setData] = useState(defaultData("pt"));
  const [width, setWidth] = useState(window.innerWidth);

  const publicUrl = process.env.PUBLIC_URL;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const loc = window.location;
    if (
      !loc.href.includes("institutovalquiriaarenas") &&
      !loc.href.includes("localhost") &&
      !loc.href.includes("surge")
    ) {
      window.location.href = loc.href.replace(
        `${loc.hostname}`,
        "institutovalquiriaarenas.com.br"
      );
    }

    setData(defaultData("pt"));

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {/* <Maintenance /> */}
      {/* <PopUp /> */}
      <nav className="navbar">
        <div className="navbar__content">
          <a
            href="/"
            className="navbar__logo"
            title="Institu Valquiria Arenas - Logo"
          >
            <img
              className="navbar__logo--img"
              src={`${publicUrl}/${data.navbar.logo}`}
              alt="Institu Valquiria Arenas - Logo"
            />
            {/* <img src={`${publicUrl }/img/logos/carimbo-blackfriday.webp`} className="black-friday" alt="Black Friday"/> */}
          </a>

          {width <= 1024 ? (
            <>
              <MenuButton />
              <div className="navbar__links-box">
                <NavbarLinks
                  links={data.navbar.links}
                  dropdown={data.navbar.dropdown}
                  buttons={data.navbar.buttons}
                />
              </div>
            </>
          ) : (
            <NavbarLinks
              links={data.navbar.links}
              dropdown={data.navbar.dropdown}
              buttons={data.navbar.buttons}
            />
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
