import React from "react";
import Title from "../../UI/organisms/Title/Title";
import { CallToOfferType } from "./Types";

import "./CallToOffer.scss";

const CallToOffer = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const data: CallToOfferType = {
    id: "empowernefro-hdf",
    title: {
      intro: "Se torne um profissional de enfermagem Expert em",
      title: "Acesso Vascular para Hemodiálise e HDF",
      // description: "Hemodiafiltração e Diálise em agudos",
    },
    flag: "Tudo sobre acesso em um único evento! Vagas limitadas!",
    link: "/curso/imersao-acesso-vascular-para-hemodialise-e-hdf",
  };

  return (
    <a href={data.link} id="call-to-offer">
      <div
        className="call-to-offer__container"
        style={{
          backgroundImage: `url(${publicUrl}/img/parts/row-quantity.svg)`,
        }}
      >
        {data.flag && (
          <div className="call-to-offer__container--flag">{data.flag}</div>
        )}
        <Title info={data.title} tag="h2" />
        <div className="call-to-offer__container--button">
          EU QUERO <span>&#10148;</span>
        </div>
      </div>
    </a>
  );
};

export default CallToOffer;
