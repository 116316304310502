import React, { FC } from "react";
import Title from "../../../UI/organisms/Title/Title";

import "./TabLayout.scss";
import { TabLayoutType } from "./Types";

const TabLayout: FC<TabLayoutType> = ({ data: { title, tabs } }) => {
  const toId = (text: string) => `${text}`.toLowerCase().replaceAll(" ", "-");

  function handleOpenTab(e: any) {
    document
      .querySelector(".tabLayout__list--item.open")
      ?.classList.remove("open");
    document
      .querySelector(".tabLayout__info--content.open")
      ?.classList.remove("open");

    e.target?.classList.add("open");
    document
      .querySelector(`.tabLayout__info--content[data-id="${e.target.id}"`)
      ?.classList.add("open");
  }

  return (
    <section className="tabLayout">
      <div className="tabLayout__content">
        {title && <Title info={title} tag="h2" />}
        {tabs.length > 0 && (
          <>
            <div className="tabLayout__list">
              {tabs.map((tab, index) => (
                <p
                  key={`${index}-id-${toId(tab.title.title)}`}
                  className={`tabLayout__list--item ${
                    index === 0 ? "open" : ""
                  }`}
                  id={toId(tab.title.title)}
                  onClick={(e) => handleOpenTab(e)}
                >
                  {" "}
                  {tab.title.title}
                </p>
              ))}
            </div>
            <div className="tabLayout__info">
              {tabs.map((tab, index) => (
                <div
                  key={`${index}-tab-${toId(tab.title.title)}`}
                  className={`tabLayout__info--content ${
                    index === 0 ? "open" : ""
                  }`}
                  data-id={toId(tab.title.title)}
                  dangerouslySetInnerHTML={{ __html: `${tab.content}` }}
                ></div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default TabLayout;
