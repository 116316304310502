import React, { FC, useEffect, useState } from "react";

import { defaultData } from "../../../data";

import Links from "../../UI/molecules/Links/Links";
import Contact from "../../UI/organisms/Contact/Contact";

import "./Footer.scss";

const Footer: FC = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const [data, setData] = useState(defaultData("pt"));

  useEffect(() => {
    setData(defaultData("pt"));
  }, []);

  return (
    <footer>
      <Contact info={data.footer.contact} />

      <div className="footer">
        <div className="footer__content">
          <img
            className="footer__logo"
            src={`${publicUrl}/${data.footer.infos.logo}`}
            alt=""
          />
          <ul className="footer__links">
            {data.footer.infos.links.map((link, index) => (
              <li key={link.ico} className="social-medias">
                <Links url={link.link} ico={`${publicUrl}/${link.ico}`} />
              </li>
            ))}
          </ul>
          <div className="footer__contact">
            <a
              title="Telefone para contato"
              href={`tel:+55${data.footer.infos.contact.tel
                .replace("(", "")
                .replace(")", "")
                .replace(" ", "")}`}
            >
              {data.footer.infos.contact.tel}
            </a>
            <a
              title="Email para contato"
              href={`mailto:${data.footer.infos.contact.email}`}
            >
              {data.footer.infos.contact.email}
            </a>
          </div>
        </div>
      </div>

      <div className="copyright">
        Copyright © 2023. Todos os Direitos Reservados
      </div>
    </footer>
  );
};

export default Footer;
