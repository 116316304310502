import React, { FC, useState } from "react";

import "./Video.scss";

import { VideoType } from "./Types";

const Video: FC<VideoType> = ({ url }) => {
  const [play, setPlay] = useState(false)

  if (url.includes("youtube") || url.includes("drive.google"))
    return (
      <iframe
        loading="lazy"
        className="elementor-video-iframe entered lazyloaded"
        title="Player de Vídeo"
        src={
          url.includes("youtube")
            ? `${url}?controls=0&autopause=0&loop=1&autoplay=0`
            : `${url}?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0&amp;autoplay=0#t=`
        }
        data-rocket-lazyload="fitvidscompatible"
        data-lazy-src={
          url.includes("youtube")
            ? `${url}?controls=0&autopause=0&loop=1&autoplay=0`
            : `${url}?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0&amp;autoplay=0#t=`
        }
        data-ll-status="loaded"
        data-ready="true"
        data-gtm-yt-inspected-7="true"
      ></iframe>
    );
  else {
    const handleVideo = {
      getvideo: (e: any) => {
        if (e.target.localName === "video")
          return e.target
        else {
          return e.target.parentElement.querySelector("video") ?
            e.target.parentElement.querySelector("video") :
            e.target.parentElement.parentElement.querySelector("video") ?
              e.target.parentElement.parentElement.querySelector("video") :
              e.target.parentElement.parentElement.parentElement.querySelector("video")
        }
      },
      click: (e: any) => {
        const video = handleVideo.getvideo(e)

        if (video.paused)
          handleVideo.play(video)
        else
          handleVideo.pause(video)
      },
      play: (video: HTMLVideoElement) => {
        video.play()
        setPlay(true)
        video.onended = function () {
          setPlay(false)
        };
      },
      pause: (video: HTMLVideoElement) => {
        video.pause()
        setPlay(false)
      }
    }

    return (
      <div className="video-block" onClick={e => handleVideo.click(e)}>
        <div className={play ? "video-play" : "video-pause"}>
          <svg className="play-ico" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C8.02218 0 6.08879 0.58649 4.44429 1.6853C2.7998 2.78412 1.51808 4.3459 0.761202 6.17317C0.00432521 8.00043 -0.193708 10.0111 0.192144 11.9509C0.577996 13.8907 1.5304 15.6725 2.92893 17.0711C4.32745 18.4696 6.10928 19.422 8.04909 19.8079C9.9889 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9996 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM8 14.5V5.5L14 10L8 14.5Z" fill="#E63247" />
          </svg>
          <svg className="pause-ico" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.93 17.07C1.9749 16.1475 1.21308 15.0441 0.688989 13.824C0.164899 12.604 -0.110963 11.2918 -0.122501 9.964C-0.13404 8.63621 0.118977 7.31941 0.621786 6.09045C1.1246 4.86148 1.86713 3.74496 2.80605 2.80604C3.74498 1.86711 4.8615 1.12458 6.09046 0.621771C7.31943 0.118962 8.63622 -0.134055 9.96402 -0.122517C11.2918 -0.110979 12.604 0.164884 13.824 0.688974C15.0441 1.21306 16.1475 1.97489 17.07 2.92999C18.8916 4.81601 19.8995 7.34203 19.8767 9.964C19.854 12.586 18.8023 15.0941 16.9482 16.9482C15.0941 18.8023 12.586 19.8539 9.96402 19.8767C7.34205 19.8995 4.81602 18.8916 2.93 17.07ZM15.66 15.66C17.1611 14.1589 18.0045 12.1229 18.0045 9.99999C18.0045 7.87708 17.1611 5.84111 15.66 4.33999C14.1589 2.83886 12.1229 1.99554 10 1.99554C7.87709 1.99554 5.84113 2.83886 4.34 4.33999C2.83888 5.84111 1.99555 7.87708 1.99555 9.99999C1.99555 12.1229 2.83888 14.1589 4.34 15.66C5.84113 17.1611 7.87709 18.0044 10 18.0044C12.1229 18.0044 14.1589 17.1611 15.66 15.66ZM7 5.99999H9V14H7V5.99999ZM11 5.99999H13V14H11V5.99999Z" fill="#E63247" />
          </svg>
        </div>
        <video id="">
          <source src={url} type="video/mp4" />
        </video>
      </div>
    )
  }
};

export default Video;
