import React, { FC } from "react";

import "./References.scss";
import { ReferenceType } from "./Types";

const References: FC<ReferenceType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section
      id="references"
      style={{
        backgroundImage: `url(${publicUrl}/img/parts/row-quantity.svg)`,
      }}
    >
      <ul className="references__content">
          {data &&
            data.map((info) => (
              <li className="references__item">
                <p className="references__item--number">{info.title}</p>
                <p className="references__item--text">{info.description}</p>
              </li>
            ))}
      </ul>
    </section>
  );
};

export default References;
